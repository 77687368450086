@use '../../styles/var';

// Define width for each header
$widthStatus: 10%;
$widthKeyword: 30%;
$widthType: 15%;
$widthWords: 15%;
$widthOpti: 15%;
$widthActions: 15%;

// Define min-height of Post
$minPostHeight: 10vh;

// Define padding of dates tag
$datePaddingX : 5px;

.objectiveTablesContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 4vh 0;
    .objectiveTable {
        width: 48%;
    }
}

.todoPostsHeaders {
    display: flex;
    width: 100%;
    padding: 0 0 3vh 0;
    .header {
        text-align: center;
    }
}

.todoPostsList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    .postContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        padding: 0 0 4vh 0;
        .post {
            display: flex;
            align-items: center;
            min-height: $minPostHeight;
            width: 100%;
            background-color: var.$darkerColor;
            border-radius: var.$borderRadius;
            .cell {
                text-align: center;
                font-size: 1.9vh;
            }
            .actionsCell, .statusCell {
                display: flex;
                justify-content: center;
            }
        }
        .datesStructure {
            display: flex;
            margin: 0 20px;
            padding: 0 20px;
            background-color: var.$darkerColor;
            border-bottom-left-radius: var.$borderRadius;
            border-bottom-right-radius: var.$borderRadius;
            .label {
                padding: $datePaddingX 20px $datePaddingX 0;
                font-style: italic;
                &:last-child {
                    padding: $datePaddingX 0;
                }
            }
            .date {
                padding: 0 0 0 5px;
                color: var.$darkFontColor;
                font-style: normal;
            }
        }
    }
}

.todoPostsHeaders, .todoPostsList {
    .widthStatus {
        width: $widthStatus;
    }
    .widthKeyword {
        width: $widthKeyword;
    }
    .widthType {
        width: $widthType;
    }
    .widthWords {
        width: $widthWords;
    }
    .widthOpti {
        width: $widthOpti;
    }
    .widthActions {
        width: $widthActions;
    }
}