/**
 * This file is imported in _reset.scss which already @use _var.scss
 * No need to specify @use rule
*/

// ******************** ROOT CONTENT ***************
._content {
    overflow-y: scroll;
    height: calc(100vh - #{var.$headerHeight} - #{var.$bottomSpace});
    margin: 0 var.$contentSpace;
    padding: var.$contentSpace;
    border-radius: var.$borderRadius;
    background-color: var.$darkColor;
}

._contentNoScroll {
    @extend ._content;
    overflow: hidden;
}

._contentCenter {
    @extend ._content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// ********************* FORM **********************
._field, ._semiField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var.$fieldPadding;
}

._labelContainer {
    display: flex;
    align-items: center;
    ._icon {
        height: 2vh;
        width: 2vh;
        margin: 0 5px 3px 0;
    }
}

._label {
    font-size: 1.8vh;
    padding: var.$labelPadding;
}

._input, ._dateInput, ._rangeInput, ._semiInput, ._select, ._semiSelect,
._textarea, ._semiTextarea {
    width: 100%;
    border: 1px solid var.$fontColor;
    border-radius: 20px;
    background-color: transparent;
    padding: 1vh 15px;
    margin: var.$inputMargin 0;
    transition: var.$transition;
    &:focus {
        border: 1px solid var.$linkColor;
        box-shadow: var.$boxShadowBlue;
    }
    &:disabled {
        border-color: var.$legendFontColor;
        background-color: var.$darkColor;
        color: var.$legendFontColor;
    }
}

._textarea, ._semiTextarea {
    border-radius: var.$borderRadius;
    resize: none;
    min-height: 10vh;
}

._select, ._semiSelect{
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    cursor: pointer;
    option {
        color: var.$darkColor;
        font-family: sans-serif;
        &:disabled {
            color: var.$legendFontColor;
        }
    }
}

._legend {
    color: var.$legendFontColor;
    font-size: 1.6vh;
}

._error, ._warning {
    padding: 5px 0 0 0;
    font-size: 1.6vh;
}

._error {
    color: var.$redColor;
}

._warning {
    color: var.$yellowColor;
}

._semiField, ._semiInput, ._semiSelect, ._semiTextarea {
    width: 48%
}

._dateInput {
    width: 180px;
}

._rangeInput {
    padding: 0;
}

// ********************** OTHER ELEMENTS ***************
._button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var.$orangeColor;
    color: white;
    border-radius: 20px;
    border: none;
    padding: 1vh 3vh;
    cursor: pointer;
    transition: var.$transition;
    & > p, span {
        color: white;
    }
    & > svg {
        fill: white;
    }
    &:hover {
        box-shadow: var.$boxShadowWhite;
    }
}

._actionButton {
    height: 3vh;
    width: 3vh;
    padding: 0.5vh;
    border: 1px solid white;
    border-radius: 5px;
    fill: var.$orangeColor;
    cursor: pointer;
    transition: var.$transition;
    &:hover {
        border: 1px solid var.$fontColor;
    }
}

._simpleInput {
    max-width: 200px;
    border: 1px solid var.$fontColor;
    border-radius: 1vh;
    background-color: transparent;
    padding: 3px 15px;
    font-size: 1.5vh;
    margin: var.$inputMargin 0;
    transition: var.$transition;
    &:focus {
        border: 1px solid var.$linkColor;
        box-shadow: var.$boxShadowBlue;
    }
    &:disabled {
        border-color: var.$legendFontColor;
        background-color: var.$darkColor;
        color: var.$legendFontColor;
    }
}

// ********************** TOAST *********************
._toast {
    display: flex;
    border: 1px solid;
    border-radius: var.$borderRadius;
    overflow: hidden;
}
._toastIcon {
    height: 100%;
    width: 40px;
    fill: white;
    padding: 7px;
}
._toastContent {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    & > p, span {
        color: white;
    }
}
._toastClose {
    height: 10px;
    width: 10px;
    fill: white;
    cursor: pointer;
    transition: var.$transition;
    &:hover {
        fill: var.$fontColor;
    }
}

._errorToast {
    @extend ._toast;
    border-color: var.$redColor;
    background-color: var.$lightRedColor;
    ._toastIcon {
        @extend ._toastIcon;
        background-color: var.$redColor;
    }
}

// ******************* HTML TAG IN COMPONENTS ********************

.purifiedHtmlComponent, .ProseMirror {
    h2 {
        display: inline-block;
        font-size: 3vh;
        margin: 2vh 0;
        * {
            font-size: 3vh;
        }
    }
    h3 {
        display: inline-block;
        font-size: 2vh;
        margin: 1vh 0;
        * {
            font-size: 2vh;
        }
    }
    h4 {
        display: inline-block;
        font-size: 1.2vh;
        margin: 0.6vh 0;
        * {
            font-size: 1.2vh;
        }
    }
    strong, strong * {
        color: white;
        font-weight: bolder;
    }
    ul, ol {
        padding: 0 0 0 1rem;
    }
    a {
        color: var.$linkColor;
        text-decoration: underline;
    }
}