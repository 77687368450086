@use '../../styles/var';

// Define Checkbox size
$checkboxSize: 2vh;

.checkboxComponent {
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    .input {
        display: none;
    }
    .label {
        position: relative;
        display: inline-block;
        height: $checkboxSize;
        width: $checkboxSize;
        margin: 0 10px 0 0;
        padding: var.$labelPadding;
        background-color: transparent;
        border: 1px solid var.$fontColor;
        border-radius: 5px;
        transition: box-shadow .1s;
        cursor: pointer;
        &:hover {
            box-shadow: var.$littleBoxShadowWhite;
        }
    }
    .input:checked + .label {
        background-color: var.$orangeColor;
    }
    .input:checked + .label:after {
        position: absolute;
        content: '\2714';
        font-size: 10px;
        top: 2px;
        left: 4px;
        color: white;
    }
    .fakeLabel {
        font-size: 1.8vh;
    }
}