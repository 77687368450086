@use '../../styles/var';

.loginFormContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: max-content;
    padding: 0 5%;
    border-right: 1px solid var.$fontColor;
    background-color: rgba(125, 125, 125, 0.5);
    box-shadow: 1vh -2px 1vh 0px rgba(0, 0, 0, 0.5);
    .form {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 3vh;
        width: 400px;
        background-color: var.$darkColor;
        border-radius: var.$borderRadius;
        .fieldset {
            width: 100%;
            border: none;
            padding: 2.5vh 0;
        }
        .logo {
            height: 8vh;
            width: 8vh;
            fill: var.$orangeColor;
            margin: 0 0 2.5vh 0;
        }
        .title {
            font-size: 2.7vh;
        }
        .subTitle {
            font-size: 1.7vh;
            color: var.$darkFontColor;
        }
        .submitIcon {
            margin: 3px 0 0 5px;
        }
    }
    .errorToast {
        margin: 20px 0 0 0;
        width: 100%;
    }
}