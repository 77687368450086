@use '../../styles/var';

.imagePreviewImage {
    width: 100%;
}

.imagePreviewClose {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: var.$orangeColor;
    border-bottom-left-radius: 2px;
    color: white;
    cursor: pointer;
    transition: var.$transition;
}