@use '../../styles/var';

.backgroundVideo {
    position: fixed;
    top: 0;
    left: 0;
    .videoControls {
        position: fixed;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        transition: var.$transition;
        &:hover {
            color: white;
        }
    }
}
