@use '../../styles/var';

.commandCreationTitle {
    font-size: 3vh;
    padding: 0 0 3vh 0;
}

.commandCreationForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .formColumn {
        width: 48%;
        .fieldset, .fieldsetSpaceBetween {
            border: none;
            margin: 0 0 4vh 0;
            padding: 3vh;
            border-radius: var.$borderRadius;
            background-color: var.$darkerColor;
            ._field {
                .optiStructure {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .value {
                        font-size: 2vh;
                    }
                }
            }
            .hasSiteCheckbox {
                padding: var.$fieldPadding;
            }
        }
        .fieldsetSpaceBetween {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .submitStructure {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 0 5vh 0;
    }
}