@use '../../styles/var';

.headerComponent {
    height: var.$headerHeight;
    min-width: 100%;
    padding: var.$headerPadding;
    .headband {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var.$headbandHeight;
        .logoContainer {
            display: flex;
            align-items: center;
            .logo {
                fill: var.$orangeColor;
                height: 4vh;
                width: 4vh;
                cursor: pointer;
            }
            .appName {
                padding: 0 0 0 20px;
                font-weight: bold;
                color: white;
                font-size: 3vh;
            }
        }
        .userContainer {
            display: flex;
            align-items: center;
            .avatar {
                height: 5vh;
                width: 5vh;
                border: 1px solid white;
                border-radius: 5px;
            }
            .userStructure {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-end;
                margin: 0 0 0 20px;
                .user, .lastname {
                    color: white;
                }
                .lastname {
                    font-style: italic;
                }
                .logoutStructure {
                    display: flex;
                    align-items: center;
                    font-size: 1.3vh;
                    font-style: italic;
                    text-decoration: underline;
                    cursor: pointer;
                    transition: var.$transition;
                    &:hover {
                        color: var.$linkColor;
                    }
                }
            }
        }
    }
    .navbarStructure {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% - #{var.$headbandHeight});
        .navbar {
            display: flex;
            align-items: center;
            .home {
                height: 2vh;
                width: 2vh;
                fill: var.$linkColor;
                cursor: pointer;
                transition: var.$transition;
                &:hover {
                    filter: drop-shadow(var.$boxShadowBlue);
                }
            }
            .link {
                padding: 0 25px;
                border: none;
                background-color: transparent;
                font-size: 1.8vh;
                font-family: 'Quicksand';
                color: var.$linkColor;
                cursor: pointer;
                transition: var.$transition;
                &:not(:last-child) {
                    border-right: 1px solid var.$darkFontColor;
                }
                &:hover {
                    text-shadow: var.$boxShadowBlue;
                }
                &.active {
                    text-shadow: var.$boxShadowBlue;
                }
            }
        }
        .previous {
            height: 70%;
            width: auto;
            fill: var.$linkColor;
            cursor: pointer;
            transition: var.$transition;
            &:hover {
                filter: drop-shadow(var.$boxShadowBlue);
            }
        }
    }
}