@use '../../styles/var';

// Define heights
$filterHeight: 6%;
$postPadding: 2vh;

.postsGestion {
    height: 100%;
    .filterStructure {
        display: flex;
        align-items: center;
        height: $filterHeight;
        .select {
            border: 1px solid var.$fontColor;
            border-radius: 10px;
            background-color: transparent;
            padding: 3px 10px;
            margin: 0 0 0 2vh;
            width: 300px;
        }
        .statusFilterTag {
            color: white;
            text-align: center;
            margin: 0 0 0 2vh;
            padding: 0.2vh 0.7vh;
            border-radius: 5px;
            font-size: 1.3vh;
            cursor: pointer;
        }
        .input {
            background-color: transparent;
            padding: 0 0 3px;
            margin: 0 0 0 2vh;
            width: 15%;
            border: none;
            border-bottom: 1px solid var.$fontColor;
        }
    }
    .postsListing {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0.5vh;
        margin: $postPadding 0 0 0;
        max-height: calc(100% - (2 * #{$filterHeight}) - #{$postPadding});
        overflow-y: scroll;
        .postContainer {
            display: flex;
            width: 100%;
            margin: $postPadding 0;
            border-radius: var.$borderRadius;
            background-color: var.$darkerColor;
            .headStructure {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 10%;
                padding: $postPadding;
                .icon {
                    height: 4vh;
                    width: 4vh;
                    fill: var.$orangeColor;
                }
                .deadline {
                    color: white;
                    font-size: 2vh;
                    padding: 0 0 2vh 0;
                }
                .statusTag {
                    font-size: 1.3vh;
                }
                &:after {
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 70%;
                    background-color: var.$orangeColor;
                    
                }
            }
            .contentStructure {
                display: flex;
                flex-wrap: wrap;
                width: 90%;
                padding: $postPadding;
                .topStructure {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    row-gap: 1vh;
                    width: 100%;
                    padding: 0 0 1vh 0;
                }
                .bottomStructure {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    .editorStructure {
                        display: flex;
                        align-items: center;
                        .avatar {
                            height: 4vh;
                            width: 4vh;
                            border-radius: 5px;
                            background-color: gray;
                            margin: 0 1vh 0 0;
                        }
                    }
                    .actionsStructure {
                        display: flex;
                        .action {
                            height: 3.6vh;
                            width: 3.6vh;
                            margin: var.$actionButtonMargin;
                        }
                    }
                }
            }
        }
        .postUrgent {
            box-shadow: var.$boxShadowRed;
        }
    }
}