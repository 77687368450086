@use '../../styles/var';

.standaloneInput {
    position: relative;
    .input {
        padding-right: 40px; // overwrite or set only right padding
    }
    .submit {
        position: absolute;
        height: 2.5vh;
        width: 2.5vh;
        right: var.$borderRadius;
        top: 54%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: var.$transition;
        &:hover {
            fill: var.$darkOrangeColor;
        }
    }
}

.semiStandaloneInput {
    width: 48%;
}