@use 'sass:math';

// Custom colors
$darkerColor: #303030;
$darkColor: #383838;
$fontColor: #BBBBBB;
$darkFontColor: #878686;
$legendFontColor: #777b7e;
$linkColor: #74ace2;
$boxShadowBlueColor: #4c84a6;
$boxShadowWhiteColor: #dddddd;
$orangeColor: #FE6531;
$darkOrangeColor: #c74c22;
$yellowColor: #FFCC00;
$redColor: #ff5252;
$lightRedColor: #f77979;
$darkGreenColor: #4da54d;
$greenColor: #77dd77;

// Default properties & others
$borderRadius: 10px;
$fieldPadding: 0 0 2.5vh 0;
$labelPadding: 0 0 3px 0;
$inputMargin: 3px 0;
$previewImageSize: 200px;
$textEditorHeight: 500px;
$actionButtonMargin: 0 2vh 0 0;

// Box-shadowing
$boxShadow: 0 0 0.5vh;
$littleBoxShadow: 0 0 3px;
$boxShadowBlue: $boxShadow $boxShadowBlueColor;
$boxShadowWhite: $boxShadow $boxShadowWhiteColor;
$littleBoxShadowWhite: $littleBoxShadow $boxShadowWhiteColor;
$boxShadowRed: $boxShadow $redColor;

// Transition
$transition: all .2s;

// Content margin & spaces
$contentSpace: 4vh;
$bottomSpace: math.div($contentSpace, 2);

// Header css property
$headerPaddingBottom: 1vh;
$headerPadding: 0 (2 * $contentSpace) $headerPaddingBottom (2 * $contentSpace);
$headerHeight: 11vh;
$headbandHeight: 60%;