@use '../../styles/var';

.ownedCommandsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: var.$contentSpace;
    column-gap: var.$contentSpace;
    .commandComponent {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3vh;
        border-radius: var.$borderRadius;
        background-color: var.$darkerColor;
        .pinIcon {
            position: absolute;
            fill: var.$orangeColor;
            height: 4vh;
            width: 4vh;
            top: -2vh;
            left: 50%;
        }
        .dataStructure {
            width: 100%;
            .dataDisplayComponent {
                padding: 0 0 2vh 0;
                .label {
                    color: var.$orangeColor;
                }
                .label, .value, .element {
                    font-size: 1.7vh;
                }
                &:first-child {
                    padding: 2vh 0;
                }
            }
        }
        .statusTag {
            margin: 0 0 2vh 0;
        }
        .action {
            height: 3.5vh;
            width: 3.5vh;
        }
    }
}