@use '../../styles/var';

// Define constantes for blocs
$textEditorWidth: 70%;

.optimizedEditor {
    display: flex;
    padding: 5vh 0 0 0;
    & > *:first-child {
        // 2% to make sure there is little space with aside div
        width: calc(100% - #{$textEditorWidth} - 2%); 
    }
    .editor {
        width: $textEditorWidth;
    }
}