@use '../../styles/var';

.imageDropzoneDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var.$legendFontColor;
    .format {
        color: var.$legendFontColor;
        font-style: italic;
    }
}