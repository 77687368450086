@use '../../styles/var';

.optimizationDataComponent {
    .askButton {
        margin: 1vh 0;
        padding: 0.5vh 1.5vh;
        border: none;
        border-radius: var.$borderRadius;
        background-color: var.$orangeColor;
        cursor: pointer;
        transition: var.$transition;
        p {
            color: white;
        }
        &:not(:disabled):hover {
            box-shadow: var.$littleBoxShadowWhite;
        }
    }
    .askButton:disabled {
        cursor: default;
        background-color: var.$darkOrangeColor;
    }
    .dataStructure {
        .score {
            display: flex;
            font-size: 3vh;
            font-weight: bold;
            padding: 0 0 2vh 0;
        }
        .score > span {
            font-size: 3vh;
            padding: 0 0 0 1vh;
        }
        .keywordsList {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            max-height: var.$textEditorHeight - 45px;
            .keywordTag {
                font-size: 1.8vh;
                border-radius: var.$borderRadius;
                padding: 0.3vh 14px;
                margin: 0 10px 10px 0;
                cursor: pointer;
                transition: var.$transition;
            }
        }
    }
}