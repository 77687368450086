@use '../../styles/var';

.formikCheckInputComponent {
    .input {
        display: none;
    }
    .labelStructure {
        display: flex;
        align-items: center;
        width: max-content;
        cursor: pointer;
        .fakeInput {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2vh;
            height: 2vh;
            margin: 0 1vh 0 0;
            background-color: transparent;
            border: 1px solid var.$fontColor;
            border-radius: 5px;
            transition: var.$transition;
        }
        .label {
            font-size: 1.8vh;
        }
    }
    .labelStructure:hover > .fakeInput {
        border: 1px solid white;
    }
    .input:checked + .labelStructure {
        .fakeInput {
            position: relative;
            background-color: var.$orangeColor;
            &::before {
                position: absolute;
                content: '\2714';
                font-size: 1.2vh;
                color: white;
            }
        }
    }
}

.formikCheckInputAsField {
    padding: var.$fieldPadding;
}