@use '../../styles/var';

.tipTapComponent {
    .tipTapToolbar {
        display: flex;
        align-items: center;
        padding: 5px 0 8px var.$borderRadius;
        .controlsStructure {
            display: flex;
            &:not(:last-child) {
                position: relative;
                padding: 0 15px 0 0;
                margin: 0 15px 0 0;
                &::after {
                    position: absolute;
                    content: '';
                    top: 50%;
                    right: -1px;
                    transform: translateY(-50%);
                    height: 2vh;
                    width: 1px;
                    background-color: var.$orangeColor;
                }
            }
            .control {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3vh;
                width: 3vh;
                padding: 0.5vh;
                border: 1px solid var.$fontColor;
                border-radius: 5px;
                background-color: transparent;
                font-weight: bolder;
                color: var.$fontColor;
                text-transform: uppercase;
                cursor: pointer;
                &:hover {
                    box-shadow: var.$littleBoxShadowWhite;
                }
                &:not(:last-child) {
                    margin: 0 5px 0 0;
                }
            }
        }
        .isActive {
            box-shadow: var.$littleBoxShadowWhite;
        }
    }
    .ProseMirror {
        height: var.$textEditorHeight;
        padding: var.$borderRadius * 2;
        border: 1px solid var.$fontColor;
        border-radius: var.$borderRadius;
        overflow-y: scroll;
        transition: var.$transition;
    }
    /* Placeholder (at the top) */
    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: var.$legendFontColor;
        pointer-events: none;
        height: 0;
    }
    .ProseMirror-focused {
        border: 1px solid var.$linkColor;
        box-shadow: var.$boxShadowBlue;
    }
}