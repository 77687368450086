@use '../../styles/var';

.objectiveTableComponent {
    padding: 3vh;
    background-color: var.$darkerColor;
    border-radius: var.$borderRadius;
    .title {
        text-align: center;
        color: white;
        font-size: 2.2vh;
        padding: 0 0 2.2vh 0;
    }
    .content {
        display: grid;
        grid-template-columns: 0.7fr 1.5fr 5fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 1.5vh;
    }
}