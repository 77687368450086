@use '../../styles/var';

.imageSelectorComponent {
    display: flex;
    align-items: center;
    margin: 10px 0;
    .arrow {
        height: 30px;
        width: 10%;
        cursor: pointer;
        transition: var.$transition;
        &:hover {
            fill: var.$orangeColor;
        }
    }
    .image {
        height: 100%;
        object-fit: contain;
        padding: 4px;
        height: var.$previewImageSize;
        width: var.$previewImageSize;
        border: 1px solid;
        border-radius: 2px;
        user-select: none;
    }
}