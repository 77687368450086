@use '../../styles/var';

.editSiteTitle {
    font-size: 3vh;
    padding: 0 0 3vh 0;
}

.editSiteForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: none;
        width: 48%;
        height: max-content;
        margin: 0 0 4vh 0;
        padding: 3vh;
        border-radius: var.$borderRadius;
        background-color: var.$darkerColor;
        .wpCategoriesStructure {
            display: flex;
            flex-wrap: wrap;
            padding: 1vh 0 0 0;
            .wpCategory {
                border-radius: var.$borderRadius;
                border: 1px solid white;
                padding: 0.5vh 1vh;
                margin: 0 1vh 1vh 0;
                cursor: pointer;
                transition: var.$transition;
                &:hover {
                    box-shadow: var.$boxShadowWhite;
                }
            }
        }
        .categoriesStructure {
            width: 48%;
        }
    }
    .submitStructure {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 0 5vh 0;
    }
}