@use 'var';
@import 'custom';

a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
html, body, div, span, applet, object, iframe, main,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, input, select, textarea,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, svg {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 1.8vh;
    color: var.$fontColor;
    font-family: 'Quicksand';
    outline: none !important;
    outline-color: transparent !important;
}

body {
    height: 100vh;
    background-color: var.$darkerColor;
}

#root {
    position: relative;
    min-height: 100vh;
}

a {
    text-decoration: none;
}

:focus {
    outline: none !important;
    outline-color: transparent !important;
    outline-style: none !important;
    outline-width: 0 !important;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand Light';
    src: url('../fonts/Quicksand-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand ExtraBold';
    src: url('../fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand Bold';
    src: url('../fonts/Quicksand-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand Medium';
    src: url('../fonts/Quicksand-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}