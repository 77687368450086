@use '../../styles/var';
@use 'sass:math';

$iconSize: 1.8vh;
$halfIconSize: math.div($iconSize, 2);

.dataDisplayComponent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .icon {
        height: $iconSize;
        width: $iconSize;
        margin: 0 8px 0 0;
        fill: var.$orangeColor;
    }
    .label, .value {
        color: white;
    }
    .value {
        padding: 0 0 0 10px;
        color: var.$fontColor;
    }
    .element {
        width: 100%;
        margin: 5px 0 0 $halfIconSize;
        padding: 5px 0 5px calc(10px + #{$halfIconSize});
        border-left: 1px solid var.$orangeColor;
    }
}