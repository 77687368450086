@use '../../styles/var';

.editorCardComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    padding: 1vh;
    border: 1px solid transparent;
    cursor: pointer;
    transition: var.$transition;
    .avatar {
        height: 5vh;
        width: 5vh;
        border-radius: 5px;
        background-color: gray;
    }
    .editor {
        text-align: center;
        padding: 1vh 0 0 0;
    }
    &:hover {
        border-color: white;
    }
}

.editorCardSelected {
    border-color: white;
    box-shadow: var.$boxShadowWhite;
}