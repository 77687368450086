@use '../../styles/var';

.reportLink {
    display: inline-block;
    color: var.$linkColor;
    text-decoration: underline;
    padding: 0 0 3vh 0;
}

.editorsGestion {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var.$contentSpace;
    row-gap: var.$contentSpace;
    .editorContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: var.$borderRadius;
        background-color: var.$darkerColor;
        .dataStructure {
            display: flex;
            padding: 3vh 3vh 1.5vh;
            .identityStructure {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
                padding: 0 2vh 0 0;
                .avatar {
                    height: 6vh;
                    width: 6vh;
                    border: 1px solid white;
                    border-radius: 5px;
                    margin: 0 0 1.5vh;
                }
                .editorName {
                    text-align: center;
                    color: white;
                    font-size: 2vh;
                    padding: 0.5vh 0;
                }
                .connection {
                    padding: 0 0 0.5vh 0;
                }
                .badConnection {
                    color: var.$redColor;
                }
            }
            .velocitiesStructure {
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 0 0 0 2vh;
                .title {
                    line-height: 1;
                    text-align: center;
                    font-size: 2.1vh;
                    padding: 0 0 2vh 0;
                }
                .velocity {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 1vh 0;
                    .value {
                        color: var.$orangeColor;
                        font-size: 2vh;
                    }
                }
            }
        }
        .actionsStructure {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.5vh 3vh 3vh;
            .action {
                height: 4vh;
                width: 4vh;
                margin: var.$actionButtonMargin;
            }
        }
    }
}