@use '../../styles/var';

// Define constantes for modal
$headerFooterHeight: 6vh;

// This overlay is used by every modals
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

// This class is used by all modals and this style don't change between modals
.ReactModal__Content {
    display: flex;
    flex-wrap: wrap;
    background-color: var.$darkColor;
    border-radius: var.$borderRadius;
    overflow: hidden;
    .header, .footer {
        padding: 0 2vh;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: $headerFooterHeight;
        background-color: var.$darkerColor;
        .title {
            font-size: 2.5vh;
        }
        .close {
            height: 2vh;
            width: 2vh;
            fill: white;
            cursor: pointer;
            transition: var.$transition;
            &:hover {
                filter: drop-shadow(var.$boxShadowWhite);
            }
        }
    }
    .body {
        padding: 3vh;
        width: 100%;
        .error {
            display: inline-block;
            text-align: center;
            width: 100%;
            padding: 1vh 0 0 0;
            color: var.$redColor;
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: $headerFooterHeight;
        .cancel, .validate {
            padding: 0.5vh 3vh;
            font-size: 1.5vh;
            text-transform: uppercase;
            border-radius: 4px;
            background-color: transparent;
            cursor: pointer;
            transition: var.$transition;
            &:hover {
                color: white;
                border-color: white;
            }
        }
        .cancel {
            margin: 0 3vh 0 0;
            color: var.$legendFontColor;
            border: 2px solid var.$legendFontColor;
        }
        .validate {
            color: var.$fontColor;
            border: 2px solid var.$fontColor;
        }
    }
}

.modalEditorPicker {
    width: 50%;
    .body {
        padding: 1vh 3vh;
        .editorsGrid {
            display: flex;
            flex-wrap: wrap;
            padding: 1%;
            width: 100%;
            height: 50vh;
            overflow-y: scroll;
            .editorCard {
                margin: 1%;
                width: 23%;
            }
        }
    }
}

.modalViewPost {
    width: 60%;
    .body {
        padding: 3vh 5vh;
        max-height: 70vh;
        overflow-y: scroll;
        .postData:not(:first-child) {
            padding: 2vh 0 0 0;
        }
        .postImage {
            display: inline-block;
            text-align: center;
            padding: 2vh 0 0 0;
            max-width: var.$previewImageSize;
            max-height: var.$previewImageSize;
        }
        .postContent {
            position: relative;
            margin: 3vh 0 0 0; 
            padding: 2vh 0 5vh 0;
            width: 100%;
            &::before {
                position: absolute;
                content: '';
                top: 0;
                height: 1px;
                width: 50%;
                transform: translateX(50%);
                background-color: white;
            }
        }
    }
    .footer {
        .cancel {
            border-color: var.$redColor;
            color: var.$redColor;
            &:hover {
                border-color: red;
                color: red;
            }
        }
    }
}

.modalInstanciateMode, .modalWaiting, .modalConfirmation, .modalDatePicker {
    width: 35%;
}

.modalInstanciateMode, .modalConfirmation {
    .body {
        text-align: center;
        p {
            font-size: 1.8vh;
        }
    }
}

.modalWaiting {
    .body {
        padding: 5vh 0;
    }
}
