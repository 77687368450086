@use '../../styles/var';

// Define constantes for blocs
$recapWidth: 25%;
$recapIconSize: 1.8vh;
$textEditorWidth: 70%;

.editPostRecap {
    width: 100%;
    padding: 2vh 4vh;
    margin: 0 0 var.$contentSpace 0;
    border-radius: var.$borderRadius;
    background-color: var.$darkerColor;
    .title {
        text-align: center;
        font-size: 3vh;
        padding: 0 0 2vh 0;
        span {
            padding: 0;
        }
    }
    .recap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 3vh;
        align-items: center;
        padding: 0 0 2vh 0;
    }
}

.editPostForm {
    display: flex;
    flex-direction: column;
    border-radius: var.$borderRadius;
    background-color: var.$darkerColor;
    .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 30px;    
        .fieldset {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            border: none;
        }
    }
    .imageSelectField {
        min-height: 270px;
    }
    .optimizationDataField {
        // 2% to make sure there is little space with aside div
        width: calc(100% - #{$textEditorWidth} - 2%); 
    }
    .textEditorField {
        width: $textEditorWidth;
    }
    .buttonOrange {
        background-color: var.$orangeColor;
        margin: 0 30px 0 0;
    }
    .buttonGreen {
        background-color: var.$greenColor;
    }
}

/*
.autoSaveStructure {
    display: flex;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    .tag {
        display: flex;
        align-items: center;
        color: white;
        font-size: 13px;
        padding: 3px 7px;
        background-color: var.$darkGreenColor;
        border-radius: 5px;
        .icon {
            fill: white;
            margin: 0 5px 0 0;
        }
    }
    .lastSave {
        padding: 0 0 0 10px;
        font-size: 13px;
    }
}
*/